<template>
	<div class="businessSector-box">
        <!-- header组件     -->
		<vheader
        :navId="navId">
		</vheader>
        <!-- header组件     -->
		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<!-- banner组件 -->
              
		<div class="businessSector-content">
            <ul>
                <div class="title05">
                    <h1>BUSINESS</h1>
                    <h2>业务板块</h2>
                </div>
                <li v-for="item in bsList" :key="item.inxex" >
                    <img :src="item.imgUrl" :alt="item.name">
                    <div>
                        <h1>{{item.name}}</h1>
                        <hr>
                        <p>{{item.word}}</p>
                    </div>
                </li>
            </ul>
        </div>
        <!-- footer组件 -->
        <vfooter></vfooter>
        <!-- footer组件 -->
        <GoTop/>
    </div>
</template>

<script>
import banner from '../../components/banner.vue'
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'businessSector',
	data () {
		return{
            navId:1,
			navName:'业务板块',
			navUrl:'businessSector',
            bsList:[
                {
                    imgUrl:require('../../assets/img/index/box04_01.jpg'),
                    name:'医疗健康',
                    word:'恒安康泰医疗健康科技管理以智慧就医保障为核心，健康咨询和医疗行程服务为辅助，打造企事业人和大众的智慧就医一体化平台，全面赋能大众健康生活。北京恒安康泰医疗健康科技，聚焦我国大众医疗重点领域，通过与北京医疗健康各合作方的高效连接，持续打造精准、细致、便捷的赴京就医新生态体系。致力于构建“赴京就医全方位医疗保障平台”，为全国各地的患者，尤其是企事业在职员工提供优质，全面，深入人心的赴京就医诊疗和健康管理服务。'
                },
                {
                    imgUrl:require('../../assets/img/index/box04_02.jpg'),
                    name:'系统开发',
                    word:'恒安康泰提供互联网医院整体搭建，专业的互联网医院系统建设服务方案，助您快速搭建互联网医院。我们提供问诊系统，挂号系统，会员系统，互联网医院开发建设，HIS开发，产品在线演示。其它服务包括预约挂号，在线预约医生到院就诊，缓解线下排队预约挂号难题。图文问诊、语音问诊、视频问诊，疑难病症医生可向患者发起线上问诊服务，让医生更准确判断病情。线上处方， 医生可线上给患者开具处方建议，并通过快递配送。互联网医院三级等保服务平台运营商，助您快速省心过等保。高效接入监管平台，标准技术接口，可独立部署，合规接入插件，高效快捷链接。协助快速拿证，帮助申办机构，快速获取互联网医院牌照。'
                },
                {
                    imgUrl:require('../../assets/img/index/box04_03.jpg'),
                    name:'实业建设',
                    word:'恒安康泰提供工程建设，工装订制，软件工程，系统开发，办公家具，办公用品六大类服务。尤其在工装定制和网站开发方面，公司实力雄厚，科技人员储备充足。曾多次为国有大型企事业单位提供高档工装定制服务，精湛的品质，一流的设计，选择恒安康泰，使您的工装订制，从此拒绝平凡。公司同时也提供网站系统开发，微信小程序开发，移动端APP定制开发，系统后期运维服务。整站全套系统和平台的开发，无论您属于何种行业，应用何种模式，庞大繁杂的整体开发请交给我们。微信小程序开发，便捷的阅览，打开，使用，微信小程序高效便捷的使用，是您开发定制的最快之选。手机移动端苹果，安卓系统定制开发，开发，上架，运维，无忧上线您的APP。长期值班式的运维服务，一站式解除您的系统运营后顾之忧。'
                },
                {
                    imgUrl:require('../../assets/img/index/box04_04.jpg'),
                    name:'教育咨询',
                    word:'我们提供教育培训，澳洲留学，中文学习，留学中国，外语翻译，口语练习等教育咨询和服务。业务范围包括，雅思培训，澳洲留学，澳洲置业，澳洲移民，专业英语，澳洲职场，国际会计，个性定制，大学论坛，英语学习，IT培训，资格认证，学历教育，才艺培训，职业培训，中文学习，中国介绍，中文介绍，中国就业，中国旅游，商务出差，旅行翻译，寻求合作，中国机会，举办活动，英语翻译，日语翻译，蒙语翻译，笔译，口译，会务翻译，合同翻译，标书翻译，证件翻译，口语在线练习，恒安康泰教育，陪您一起成长。'
                },
            ]
        }
	},
	props: {
	},
	methods:{		
		
	},
	components:{
		banner,
		vheader,
		vfooter,
        GoTop
	},
    
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
	.businessSector-box{
	    .businessSector-content{
            width: 1200Px;
            margin: auto;   
            ul{
                margin: 37Px 0 80Px;                
                .title05{
                        :first-child{
                            font-size: 42Px;
                            color: #ddd;
                            text-align: center;
                        }
                        :nth-child(2){
                            width: 89Px;
                            margin:0 auto 30Px;
                            font-size: 22Px;
                            padding-bottom: 13Px;
                            color: #222;
                            border-bottom: 1Px solid #093C6C;
                        }
                }
                li{
                    background-color: #F5F5F5;
                    display: flex;
                    align-items: center;
                    height: 300Px;
                    margin-bottom: 40Px;
                    img{
                        width: 428Px;
                        height: 300Px;
                        cursor: pointer;
                        transition: all 0.3s;
                        &:hover{
                            opacity: 0.8;
                        }                        
                    }
                    h1{
                        padding: 0 55Px;
                        font-size: 20Px;                        
                        cursor: pointer;
                        transition: all 0.2s;
                        &:hover{
                            color: #093C6C;
                        }   
                    }
                    hr{
                        margin: 16Px  55Px 14Px;
                        width: 86Px;
                        border-top: 1Px solid #093C6C;
                    }
                    p{
                        padding: 0 55Px;
                        font-size: 14Px;
                        color: #5a5a5a;
                        line-height: 30Px;
                    }
                }
            }
        }
	}
}

// 移动端样式
@media screen and (max-width:750px){
    .businessSector-box{
	    .businessSector-content{
            width: 95%;
            margin:10px auto  30px;   
            ul{
                margin: 10px 0 10px;
                .title05{
                    width: 95%;
                    :first-child{
                        font-size: 25px;
                        color: #ddd;
                        text-align: center;
                    }
                    :nth-child(2){
                        width: 65px;
                        font-size: 16px;
                        color: #222;
                        margin:0 auto 12px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid #093C6C;
                    }
			    }
                li{
                    background-color: #F5F5F5;
                    margin-bottom: 15px;
                    img{
                        width: 100%;                      
                    }
                    h1{
                        margin-top: 10px;
                        font-size: 20px;  
                        text-align: center;
                        cursor: pointer;
                        transition: all 0.2s;
                        &:hover{
                            color: #093C6C;
                        }   
                    }
                    hr{
                        margin: 10px auto 10px;
                        width: 86px;
                        border-top: 1px solid #093C6C;
                    }
                    p{
                        padding: 0 10px 10px;
                        font-size: 14px;
                        color: #5a5a5a;
                        line-height: 30px;
                    }
                }
            }
        }
	}
}
</style>
